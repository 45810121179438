<template>
  <div></div>
</template>

<script>
import request from '/src/utils/request.js'
export default {
  created() {
    // 是否已完成调研
    request.post('/traningVote2021/getAnswer', { voteId: 25 }).then((res) => {
      if (res.code === 1000) {
        let data = res.data
        if (data instanceof Array && !data.length) {
          this.$router.replace('/home')
        } else {
          this.$router.replace({ path: 'finish', query: { type: 'index' } })
        }
      }
    })
  },
}
</script>